<template>
	<div class="">
		<div class="row" v-if="loading">
			<div>
				<div class="center text-center p-4">
					<span class="fe fe-spinner">Loading..</span>
				</div>
			</div>
		</div>

		<div class="row" v-else>
			<div class="col-12" v-if="currentCreditSystem">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<h4>Fund Credit Wallet For Select Staff</h4>
							</div>
						</div>

						<div class="alert alert-danger" v-if="error">
							<span>{{ error }}</span>
						</div>

						<form @submit.prevent="applyCreditLine">
							<div class="form-group">
								<label for="">How much credit do you want to apply?</label>
								<input
									v-model.number="form.amount"
									required
									type="number"
									name="amount"
									id="amount"
									class="form-control"
									/>
							</div>

							<div class="form-group">
								<label for="">Narration for this credit</label>
								<input
									v-model.trim="form.narration"
									type="text"
									name="narration"
									id="narration"
									class="form-control"
									/>
							</div>

							<div class="form-group">
								<label for=""
									>Which employees do you want to give this credit to?</label
									>

								<div class="mb-3">
									<label
										for="employee_selector_dropdown"
										class="d-inline-block mr-2"
										><input
											type="radio"
											class="custom-radio"
											v-model="form.employee_selector"
											value="select"
											id="employee_selector_dropdown"
											name="employee_selector"
											/>
										Select from drop down</label
										>
									<label for="employee_selector_csv" class="d-inline-block mr-2"
										><input
											type="radio"
											class="custom-radio"
											v-model="form.employee_selector"
											value="csv"
											id="employee_selector_csv"
											name="employee_selector"
											/>
										Select from csv document</label
										>
								</div>

								<div v-if="form.employee_selector == 'csv'">
									<label for=""
										>Upload a csv file with the email addresses of the staff.
										<a href="#" @click.prevent="downloadCsvTemplate"
											>Download template here</a
											></label
										>
									<div>
										<input
											type="file"
											name="csv_file"
											class="btn file-select"
											id="csv_file"
											accept="text/csv"
											ref="staffCsvFile"
											@change="validateUploadedUsers"
											/>
									</div>
								</div>

								<div v-if="form.employee_selector == 'select'">
									<multiselect
										v-model="form.only_selected_staff"
										id="ajax"
										:custom-label="fullName"
										track-by="id"
										placeholder="Type to search"
										open-direction="bottom"
										:options="staffList"
										:multiple="true"
										:searchable="true"
										:loading="isLoading"
										:internal-search="false"
										:clear-on-select="false"
										:close-on-select="false"
										:options-limit="300"
										:limit="10"
										:limit-text="limitText"
										:show-no-results="false"
										:hide-selected="true"
										@search-change="asyncFindEmployee"
										>
										<template slot="clear" slot-scope="props">
											<div
												class="multiselect__clear"
												v-if="form?.only_selected_staff"
												@mousedown.prevent.stop="clearAll(props.search)"
												></div> </template
											><span slot="noResult"
											>Oops! No elements found. Consider changing the search
											query.</span
											>
									</multiselect>
								</div>
							</div>

							<div class="form-group mt-2">
								<label for="schedule_later" class="schedule_later">
									<input
										type="checkbox"
										name="schedule_later"
										class="custom-checkbox"
										id="reset"
										v-model="form.scheduled"
										/>
									Schedule to apply later
								</label>
							</div>
							<div class="form-group" v-if="form.scheduled">
								<label class="form-label"
									>Choose date and time to schedule execution</label
									>
								<div style="width: 100%">
									<v-datepicker
										v-model="form.scheduled_for"
										type="datetime"
										:show-time-header="true"
										:minute-step="10"
										:show-second="false"
										style="width: 100%"
										:use12h="true"
										></v-datepicker>
								</div>
							</div>

							<div class="checkbox_">
								<label for="reset" class="reset_label_">
									<input
										type="checkbox"
										name="reset"
										id="reset"
										@change="toggleCheckbox()"
										/>
									Clear previous balance</label
									>
							</div>

							<div class="form-group">
								<button type="submit" class="btn btn-dark" :disabled="creating">
									{{ creating ? 'Processing..' : 'Fund Credit Wallet' }}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import saveToDisk from '@/mixins/save-to-disk'
export default {
  components: { Multiselect },
  mixins: [saveToDisk],
  props: {
    companyId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      error: null,
      perPage: 20,
      currentPage: 1,
      form: {
        scheduled: false,
        scheduled_for: null,
        only_selected_staff: [],
        narration: '',
        amount: 0,
        employee_selector: 'select',
        selectedEmployee: null
      },
      staffList: [],
      isLoading: false,
      loading: false,
      creating: false,
      currentCreditSystem: null,
      toggle: false,
      incrementAmount: 1,
      fetchingCorporateStaff: false
    }
  },
  mounted () {
    this.fetchCompanyCreditSystem(this.companyId)
  },
  created () {
    this.fetchCorporatesStaff()
  },

  methods: {
    validateUploadedUsers (e) {
      if (!this.$refs.staffCsvFile.files?.length) return
      this.form.selectedEmployee = this.$refs.staffCsvFile.files[0]
    },
    downloadCsvTemplate () {
      this.exportAsCsv(
        {
          email: ''
        },
        'import-staff'
      )
    },
    async fetchCompanyCreditSystem (companyId) {
      if (this.loading) return

      try {
        this.loading = true
        const result = await this.axios.get(
          `/v1/corporates/${companyId}/credit-system`
        )
        this.currentCreditSystem = result.data
        this.loading = false
      } catch (e) {
        this.currentCreditSystem = null
        this.error = e.toString()
        if (e.response && e.response.status == 404) {
          this.needCreditSystem = true
        }
        this.loading = false
      }
    },
    toggleCheckbox () {
      this.toggle = !this.toggle
      if (this.toggle) {
        this.incrementAmount = 0
      } else {
        this.incrementAmount = 1
      }
    },
    limitText (count) {
      return `and ${count} other staff`
    },
    applyCreditLine () {
      if (this.creating) return

      this.error = null

      const employeeNotSelected =
        this.form.only_selected_staff == null ||
        !this.form?.only_selected_staff?.length

      if (this.form.selectedEmployee == null && employeeNotSelected) {
        this.error = 'All fields are required'
        return
      }

      this.creating = true
      let staffIds

      const payload = {
        narration: this.form.narration,
        amount: this.form.amount,
        increment_credit_wallet: this.incrementAmount,
        is_topup: true,
        selected_employee: this.form.selectedEmployee,
        scheduled: this.form.scheduled,
        scheduled_for: this.form.scheduled_for?.toISOString()
      }

      if (
        this.form?.only_selected_staff &&
        this.form?.only_selected_staff?.length
      ) {
        staffIds = this.form.only_selected_staff.map((m) => m.id)
        payload.only_selected_staff = staffIds
      }

      let formData = new FormData()
      for (const key in payload) {
        if (payload[key] !== undefined) {
          formData.append(key, payload[key])
        }
      }

      const hasCsvFileUpload = !!this.form.selectedEmployee
      const headers = {
        'Content-Type': 'multipart/form-data'
      }

      const options = {}
      if (hasCsvFileUpload) {
        options.headers = headers
      }

      if (!hasCsvFileUpload) {
        formData = payload
      }

      this.axios
        .post(
          `/v1/credit-systems/${this.currentCreditSystem.id}/executions`,
          formData,
          options
        )
        .then(() => {
          this.creating = false
          this.$router.push({
            name: 'ShowCreditLine',
            params: {
              companyId: this.companyId
            }
          })
        })
        .catch((err) => {
          this.error = err.toString()
          if (err.response && err.response.data && err.response.data.message) {
            this.error = err.response.data.message
          }
          this.creating = false
        })
    },
    asyncFindEmployee (query) {
      this.isLoading = true
      this.axios
        .get(
          `/v1/corporates/${this.companyId}/staff?limit=${this.perPage}&page=${this.currentPage}`,
          { params: { query } }
        )
        .then((response) => {
          this.staffList = response.data.data
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.staffList = []
        })
    },

    fetchCorporatesStaff () {
      this.fetchingCorporateStaff = true
      this.axios
        .get(`/v1/corporates/${this.companyId}/staff`)
        .then((res) => {
          this.staffList = res.data.data
          this.fetchingCorporateStaff = false
        })
        .catch(() => {
          this.fetchingCorporateStaff = false
          this.staffList = []
        })
        .finally(() => {
          this.fetchingCorporateStaff = false
        })
    },
    clearAll () {
      this.form.only_selected_staff = []
    },
    fullName ({ fname, lname }) {
      return `${fname} ${lname}`
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.reset_label_ {
  margin-left: 5px;
}
.checkbox_ {
  margin-bottom: 2%;
}
</style>
