import _ from 'lodash'
import Papa from 'papaparse'

export default {
  methods: {
    exportAsCsv (object, fileName) {
      const o = _.isArray(object) ? object[0] : object
      const keys = Object.keys(o)
      const values = Object.values(o)

      if (_.isArray(object)) {
        values.concat(object.map((i) => Object.values(i)))
      }

      const csvData = Papa.unparse(
        {
          fields: keys,
          data: [values]
        },
        {
          header: true
        }
      )
      this.saveToDisk(fileName || `file-${Date.now()}.csv`, csvData)
    },
    saveToDisk (filename, data) {
      const blob = new Blob([data], { type: 'text/csv' })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    }
  }
}
